import { ConnectorType } from 'features/charge-points/types';
import { getSelectOptionsFromRecord } from 'core/utils/selectUtils';
import { WebTranslateKey } from 'i18n/types';
import { ConnectorStatus } from 'core/types/Connector';

export const connectorStatusColor: Record<ConnectorStatus, string> = {
  [ConnectorStatus.Off]: '#cc3131',
  [ConnectorStatus.Faulted]: '#cc3131',
  [ConnectorStatus.On]: '#2a8522',
  [ConnectorStatus.Busy]: '#d0c62d',
  [ConnectorStatus.Pre]: '#333333',
  [ConnectorStatus.Res]: '#d0c62d',
};

export const connectorStatusLabel: Record<ConnectorStatus, WebTranslateKey> = {
  [ConnectorStatus.Off]: 'chargepoints.text.off',
  [ConnectorStatus.Faulted]: 'chargepoints.text.faulted',
  [ConnectorStatus.On]: 'chargepoints.text.on',
  [ConnectorStatus.Busy]: 'chargepoints.text.busy',
  [ConnectorStatus.Pre]: 'chargepoints.text.preparing',
  [ConnectorStatus.Res]: 'chargepoints.text.reservation',
};

// prettier-ignore
export const connectorsLabels: Record<ConnectorType, string> = {
  'ccs': 'CCS',
  'ccs2-combo': 'CCS2 combo',
  'chademo': 'CHAdeMO',
  'type-1': 'Type 1',
  'type-3': 'Type 3',
  'type-3c': 'Type 3C',
  'chaoji': 'ChaoJi',
  'plug-a2': 'Plug-A2',
  'plug-b3': 'Plug-B3',
  'plug-c2': 'Plug-C2',
  'plug-d3': 'Plug-D3',
  'plug-e3': 'Plug-E3',
  'plug-g3': 'Plug-G3',
  'plug-h3': 'Plug-H3',
  'plug-i3': 'Plug-I3',
  'plug-j3': 'Plug-J3',
  'plug-k3': 'Plug-K3',
  'plug-l3': 'Plug-L3',
  'plug-m3': 'Plug-M3',
  'plug-n3': 'Plug-N3',
  'plug-o3': 'Plug-O3',
  'iec-309-blue-16': 'IEC-309-BLUE-16',
  'iec-309-red-16': 'IEC-309-RED-16',
  'iec-309-red-32': 'IEC-309-RED-32',
  'iec-309-blue-64': 'IEC-309-BLUE-64',
  'nema-5-20': 'NEMA-5-20',
  'nema-6-30': 'NEMA-6-30',
  'nema-6-50': 'NEMA-6-50',
  'nema-10-30': 'NEMA-10-30',
  'nema-10-50': 'NEMA-10-50',
  'nema-14-30': 'NEMA-14-30',
  'nema-14-50': 'NEMA-14-50',
  'bus-up': 'BUS-UP',
  'bus-down': 'BUS-DOWN',
  // @deprecated
  'gbt': 'GB/T',
  'gbt-ac': 'GB/T AC',
  'gbt-dc': 'GB/T DC',
  'shuko': 'Shuko',
  // @deprecated
  'tesla': 'Tesla',
  'tesla-r': 'Tesla-R',
  'tesla-s': 'Tesla-S',
  'type-2': 'Type 2',
};

export const getConnectorsSelectOptions = (): {
  value: string;
  label: string;
}[] => {
  return getSelectOptionsFromRecord(connectorsLabels).filter(
    ({ value }) => !['tesla', 'gbt'].includes(value),
  );
};

export type PowerType = 'ac-1' | 'ac-2' | 'ac-2-split' | 'ac-3' | 'dc';

// prettier-ignore
export const powerTypeLabels: Record<PowerType, WebTranslateKey> = {
  'ac-1': 'chargepoints.text.ac-1-phase',
  'ac-2': 'chargepoints.text.ac-2-phase',
  'ac-2-split': 'chargepoints.text.ac-2-split',
  'ac-3': 'chargepoints.text.ac-3-phase',
  dc: 'chargepoints.text.dc',
};

export const getPowerTypeSelectOptions = (): {
  value: string;
  label: string;
}[] => getSelectOptionsFromRecord(powerTypeLabels);

export type ConnectorFormat = 's' | 'c';

export const connectorFormatLabels: Record<ConnectorFormat, WebTranslateKey> = {
  s: 'chargepoints.text.socket',
  c: 'chargepoints.text.cable',
};
