import { FirmwareUploadStatus } from 'features/charge-points/types/responses';

export enum StatusColor {
  Green = 'green',
  Blue = 'blue',
  Red = 'red',
  Gray = 'gray',
}

export type UploadStatus = {
  label: string;
  color: StatusColor;
  isFinished: boolean;
};

export type UploadStatusLabels = Record<FirmwareUploadStatus, UploadStatus>;
