import { FC, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Padding } from 'components/Padding';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { Typography, Button, Spin, Empty, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { TariffGroupsList } from 'pages/Pricing/TariffGroups/TariffGroupsList';
import {
  usePartnerTariffGroupDeleteMutation,
  usePartnerTariffGroupsQuery,
} from 'features/partners-tariffs-groups/queries';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { showToastMessage } from 'core/utils/showToastMessage';

import s from './TariffGroups.module.css';

type Props = {
  className?: string;
};

const TariffGroups: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();
  const [withArchived, setWithArchived] = useState(false);

  const deleteTariffGroupMutation = usePartnerTariffGroupDeleteMutation();

  const partnerId = usePartnerId();

  const {
    data: tariffGroups,
    isLoading,
    refetch: refetchGroups,
  } = usePartnerTariffGroupsQuery({ partnerId, withArchived });

  const handleDelete = (groupId: string) => {
    deleteTariffGroupMutation.mutate(
      [
        {
          partnerId,
          groupId,
        },
      ],
      {
        onSuccess: () => refetchGroups(),
        onError: (error) => {
          showToastMessage('error', errorT(error?.translationKey, error.message));
        }
      },
    );
  };

  return (
    <div className={cn(s.wrapper, className)}>
      <>
        <Padding isHorizontal>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {t('pricing.titles.tariff-groups')}
          </Typography.Title>
          <Switch
            style={{
              marginLeft: 'auto',
              alignSelf: 'center',
              marginRight: '16px',
            }}
            checkedChildren={t('pricing.labels.show-archived')}
            unCheckedChildren={t('pricing.labels.hide-archived')}
            onChange={setWithArchived}
            checked={withArchived}
            size="default"
          />
          <Link to={'/pricing/group/new'}>
            <Button type={'primary'}>
              {t('pricing.actions.add-tariff-group')}
            </Button>
          </Link>
        </Padding>

        <Padding noStart>
          {!tariffGroups && !isLoading && <Empty />}

          {isLoading && <Spin />}

          {!!tariffGroups && (
            <TariffGroupsList
              tariffGroups={tariffGroups}
              onDelete={handleDelete}
            />
          )}
        </Padding>
      </>
    </div>
  );
};

export default TariffGroups;
