import {
  FC,
  memo,
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import {
  Col,
  Row,
  Form,
  AutoComplete,
  InputNumber,
  FormInstance,
  Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { Coordinates, MapRefType } from '../Map/types';
import { Map } from '../Map';

type Props = {
  form: FormInstance;
  onLoading: (value: boolean) => void;
  coordinates: Coordinates;
  setIsFormChanged: (value: boolean) => void;
};

type Option = { value: string; placeId?: string };

const defaultCoordinates = {
  lat: 0,
  lng: 0,
};

const Address: FC<Props> = ({
  form,
  onLoading,
  coordinates,
  setIsFormChanged,
}) => {
  const mapRef = useRef<MapRefType>(null);

  const lat = parseFloat(Form.useWatch('lat', form) ?? '0');
  const lon = parseFloat(Form.useWatch('lon', form) ?? '0');

  const [addressIsLoading, setAddressIsLoading] = useState(false);

  const { t } = useTranslation();

  const [autoCompleteOptions, setAutoCompleteOptions] = useState<Option[]>([]);
  const fetchMapAddress = useDebouncedCallback(
    async (coordinates: Coordinates) => {
      setAddressIsLoading(true);

      const address = await mapRef.current?.getAddressByCoordinates(
        coordinates,
      );
      form.setFieldValue('address', address);
      setIsFormChanged(true);
      setAddressIsLoading(false);
    },
    500,
  );

  useEffect(() => {
    const hasFormCoordinates = lat !== 0 && lon !== 0;
    if (
      hasFormCoordinates &&
      (lat !== coordinates.lat || lon !== coordinates.lng)
    ) {
      fetchMapAddress({ lat, lng: lon });
    }
  }, [lat, lon, fetchMapAddress, coordinates]);

  const handleSearchMap = useDebouncedCallback(async (value: string) => {
    const suggestions = await mapRef.current?.getSuggestionsOptions(value);
    setAutoCompleteOptions(suggestions ?? []);
    onLoading(false);
  }, 500);

  const handleSelectLocation = useCallback(
    async ({ value, placeId }: Option) => {
      onLoading(true);
      const coordinates = await mapRef.current?.getCoordinatesByAddress(
        value,
        placeId,
      );

      onLoading(false);

      form.setFieldValue('lat', coordinates?.lat);
      form.setFieldValue('lon', coordinates?.lng);
      setIsFormChanged(true);
    },
    [form, onLoading, setIsFormChanged],
  );

  const markerCoordinates = useMemo(
    () => ({
      lat,
      lng: lon,
    }),
    [lat, lon],
  );

  return (
    <Col span={12} offset={2}>
      <Row>
        <Form.Item
          name="address"
          label={`${t('locations.labels.address')}:`}
          style={{ width: '100%' }}
        >
          <AutoComplete
            options={autoCompleteOptions}
            placeholder={t('locations.placeholders.enter-address')}
            onChange={handleSearchMap}
            onSelect={(_, option) => handleSelectLocation(option)}
            disabled={addressIsLoading}
            suffixIcon={addressIsLoading ? <Spin size="small" /> : null}
          />
        </Form.Item>
      </Row>

      <Row>
        <Form.Item label={`${t('locations.labels.latitude')}:`} name="lat">
          <InputNumber
            placeholder={t('locations.placeholders.enter-latitude')}
            min={0}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Row>

      <Row>
        <Form.Item label={`${t('locations.labels.longitude')}:`} name="lon">
          <InputNumber
            placeholder={t('locations.placeholders.enter-longitude')}
            min={0}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Row>

      <Row>
        <Map
          initialCoordinates={{
            lat: coordinates.lat ?? defaultCoordinates.lat,
            lng: coordinates.lng ?? defaultCoordinates.lng,
          }}
          markerCoordinates={markerCoordinates}
          onChangeCoordinates={({ lat, lng }) => {
            form.setFieldValue('lat', lat);
            form.setFieldValue('lon', lng);
            setIsFormChanged(true);
          }}
          ref={mapRef}
        />
      </Row>
    </Col>
  );
};

export default memo(Address);
