import { Spin, Table, Typography } from 'antd';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'components/Pagination';
import { useTransactionsSearchQuery } from 'features/transactions/queries';
import { ReqTransactionsSearchParams } from 'features/transactions/types/requests';
import { mapTransactionStatusToTranslationKey } from 'pages/Transactions/TransactionsFilter/helpers';
import {
  TransactionStatus,
  TransactionsFilterState,
} from 'pages/Transactions/TransactionsFilter/types';

import styles from './TransactionsTable.module.css';
import type { TransactionsTableColumns } from './useTransactionsTableColumns';

interface TransactionsTableProps {
  deviceId?: string;
  partnerId?: string;
  clientId?: string;
  tableColumns: TransactionsTableColumns;
  filter?: TransactionsFilterState;
}

const PAGE_SIZE = 15;

export const TransactionsTable: FC<TransactionsTableProps> = ({
  deviceId,
  partnerId,
  clientId,
  tableColumns,
  filter,
}) => {
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const searchParams = useMemo(() => {
    if (!filter) return {};

    /** Render table from first page when filters updated */
    setPage(1);

    const params: ReqTransactionsSearchParams = {
      statuses: filter.statuses.join(','),
      connTypes: filter.connectorTypes?.join(','),
      hideZeroAmounts: filter.hideZeroAmounts,
      amountFrom: filter.amountFrom,
      amountTo: filter.amountTo,
      startAtFrom: filter.startDateFrom,
      startAtTo: filter.startDateTo,
      deviceTransactionId: filter.deviceTransactionId,
      cpId: filter.chargePointId,
    };

    return params;
  }, [filter]);

  const query = useTransactionsSearchQuery({
    deviceId,
    partnerId,
    clientId,
    index: page,
    size: PAGE_SIZE,
    ...searchParams,
  });

  const dataSource = useMemo(() => {
    return (query.data?.items ?? []).map((transaction) => ({
      key: transaction.id,
      ...transaction,
      status: t(
        mapTransactionStatusToTranslationKey[
          transaction.status as TransactionStatus
        ],
      ),
    }));
  }, [query.data?.items, t]);

  /** we don't need to show pagination if theres in now data at all */
  const showPagination = dataSource.length > 0 || page > 1;

  if (query.isLoading) {
    return <Spin />;
  }

  return (
    <>
      <Table
        size="small"
        pagination={false}
        columns={tableColumns}
        dataSource={dataSource}
        className={styles.transactions__table}
        scroll={{ x: 1300 }}
      />
      {showPagination && (
        <div style={{ paddingBlock: '16px' }}>
          <Pagination
            page={page}
            hasNext={query?.data?.items?.length === PAGE_SIZE}
            hasPrev={page > 1}
            onChange={(_, index) => setPage(index)}
          >
            <Typography>{`${t('common.pagination.page')}: ${page}`}</Typography>
          </Pagination>
        </div>
      )}
    </>
  );
};
