import { DatePicker, Radio, RadioChangeEvent, Typography } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import { useTranslation } from 'react-i18next';

import styles from '../Dashboard.module.css';
import { DetailedStatisticsProps } from '../types';
import { useStatisticsDateOptions } from '../hooks/useStatisticsDateOptions';

const { RangePicker } = DatePicker;

export const DetailedStatistics = ({
  selectedDateOption,
  setSelectedDateOption,
}: DetailedStatisticsProps) => {
  const { t } = useTranslation();
  const statisticsDateOptions = useStatisticsDateOptions();

  const onDatesRadioChange = (e: RadioChangeEvent) => {
    const { value } = e.target;

    const dateOption = statisticsDateOptions.find(
      ({ value: optionValue }) => optionValue === value,
    );

    if (!dateOption?.dates) {
      return;
    }

    setSelectedDateOption(dateOption);
  };

  const onDatesRangeChange: RangePickerProps['onChange'] = (dates) => {
    if (!dates || !dates[0] || !dates[1]) {
      return;
    }

    const startDate = dates[0];
    const endDate = dates[1];

    setSelectedDateOption({
      label: 'Custom',
      value: 'custom',
      dates: [startDate, endDate],
    });
  };

  return (
    <>
      <Typography.Title className={styles.dashboard__stats_title} level={4}>
        {t('dashboard.titles.statistics')}
      </Typography.Title>
      <div className={styles.dashboard__date_options}>
        <Radio.Group
          value={selectedDateOption.value}
          onChange={onDatesRadioChange}
        >
          {statisticsDateOptions.map(({ label, value }) => (
            <Radio.Button value={value} key={value}>
              {label}
            </Radio.Button>
          ))}
        </Radio.Group>
        <RangePicker
          value={selectedDateOption.dates}
          onChange={onDatesRangeChange}
          style={{ maxWidth: 240 }}
        />
      </div>
    </>
  );
};
