import { PropsWithChildren, useState } from 'react';
import { Button, Modal, Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useChargePointsDeviceResetMutation } from 'features/charge-points/queries';
import { HttpError } from 'core/types';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';
import { Connector } from 'core/types/Connector';
import { useToastMessage } from 'core/hooks/useToastMessage';

interface Props {
  resetType: 'Hard' | 'Soft' | null;
  onClose: () => void;
}

const getChargePointConnectorOptions = (
  connectors: Record<number, Connector>,
) => {
  return Object.entries(connectors).map(([key, value]) => ({
    value: key,
    label: `[${key}] ${value.type}`,
  }));
};

export function ResetConfirm({ resetType, onClose }: PropsWithChildren<Props>) {
  const isOpened = !!resetType;
  const partnerId = usePartnerId();
  const showToastMessage = useToastMessage();
  const [selectedConnectorId, setSelectedConnectorId] = useState<
    string | undefined
  >(undefined);

  const { chargePoint, isOcpp2_0_1 } = useChargePointsDetailsContext();

  const chargePointConnectors = chargePoint.connectors;

  const reset = useChargePointsDeviceResetMutation();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const submit = () => {
    if (!resetType) {
      showToastMessage(t('chargepoints.errors.reset-unknown-type'), 'error');
      return;
    }

    reset
      .mutateAsync({
        partnerId,
        chargePointId: chargePoint.id,
        resetType,
        connectorId: selectedConnectorId,
      })
      .then(() => {
        showToastMessage(t('common.status.success'), 'success');
        setSelectedConnectorId(undefined);
        onClose();
      })
      .catch((e: HttpError) => {
        showToastMessage(errorT(e?.translationKey, e.message), 'error');
      });
  };

  return (
    <>
      <Modal
        title={`${resetType} reset`}
        open={isOpened}
        onCancel={onClose}
        footer={[
          <Button key={'cancel'} type={'default'} onClick={onClose}>
            {t('common.actions.cancel')}
          </Button>,
          <Button
            key={'submit'}
            type={'primary'}
            htmlType={'submit'}
            loading={reset.isLoading}
            onClick={submit}
          >
            {t('common.actions.reset')}
          </Button>,
        ]}
      >
        <Typography.Text>
          {t('chargepoints.reset_station_modal.title')}
        </Typography.Text>
        {isOcpp2_0_1 && (
          <div style={{ paddingBlock: '16px' }}>
            <Typography.Text>
              {t('chargepoints.text.reset-connector')}:
            </Typography.Text>
            <Select
              style={{ width: '100%' }}
              allowClear
              value={selectedConnectorId}
              placeholder={t('chargepoints.placeholders.reset-connectors')}
              onChange={(value) => setSelectedConnectorId(value)}
              options={getChargePointConnectorOptions(chargePointConnectors)}
            />
          </div>
        )}
      </Modal>
    </>
  );
}
