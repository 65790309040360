import { useEffect, useState } from 'react';
import {
  App,
  Button,
  Col,
  DatePicker,
  Descriptions,
  DescriptionsProps,
  Form,
  Row,
  Switch,
  Typography,
} from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  USE_LOCATION_PARTNER_QUERY_KEY,
  useLocationUpdateMutation,
} from 'features/locations/queries';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { useToastMessage } from 'core/hooks/useToastMessage';

import {
  defaultLocationWorkingHoursData,
  getPayloadFromFormValues,
  prepareFormValues,
} from './utils';
import type { FormValues, LocationWorkingHoursFormProps } from './types';

const { RangePicker } = DatePicker;

const getItems = (disabled: boolean): DescriptionsProps['items'] =>
  defaultLocationWorkingHoursData.map((item) => ({
    key: item.value,
    label: `${item.label}:`,
    children: (
      <Form.Item name={item.value} style={{ marginBottom: 0 }}>
        <RangePicker
          showTime={{ format: 'HH:mm' }}
          format="HH:mm"
          picker="time"
          disabled={disabled}
          style={{ maxWidth: 240 }}
        />
      </Form.Item>
    ),
  }));

export const LocationWorkingHours = ({
  locationId,
  workingHours,
}: LocationWorkingHoursFormProps) => {
  const [form] = Form.useForm<FormValues>();
  const { modal } = App.useApp();
  const showToastMessage = useToastMessage();
  const [isEnableWorkingHours, setIsEnableWorkingHours] = useState(
    workingHours?.restricted ?? false,
  );

  const queryClient = useQueryClient();
  const partnerId = usePartnerId();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const locationMutation = useLocationUpdateMutation();

  const handleSubmit = (values: FormValues) => {
    const payload = getPayloadFromFormValues(values);
    console.log(payload);

    modal.confirm({
      title: t('locations.titles.update-location'),
      content: t('locations.text.confirm-update-location'),
      onOk: () => {
        locationMutation
          .mutateAsync([
            partnerId,
            locationId,
            {
              workingHours: {
                regularHours: payload,
                restricted: isEnableWorkingHours,
              },
            },
          ])
          .then((location) => {
            queryClient.setQueryData(
              [USE_LOCATION_PARTNER_QUERY_KEY, partnerId, locationId],
              location,
            );

            showToastMessage(t('common.status.success'), 'success');
          })
          .catch((error) => {
            showToastMessage(
              errorT(error?.translationKey, error.message),
              'error',
            );
          });
      },
    });
  };

  useEffect(() => {
    const formValues = prepareFormValues(workingHours.regularHours);
    form.setFieldsValue(formValues);

    return () => {
      form.resetFields();
    };
  }, [form, workingHours]);

  return (
    <>
      <Row gutter={8} style={{ marginBottom: 16 }}>
        <Col>
          <Typography.Text>24/7</Typography.Text>
        </Col>
        <Col>
          <Switch
            value={isEnableWorkingHours}
            onChange={setIsEnableWorkingHours}
          />
        </Col>
        <Col>
          <Typography.Text>
            {t('locations.titles.location-working-hours')}
          </Typography.Text>
        </Col>
      </Row>

      <Form form={form} onFinish={handleSubmit}>
        <Descriptions
          items={getItems(!isEnableWorkingHours)}
          bordered
          size="small"
          column={1}
          style={{
            width: 'fit-content',
            marginBottom: 16,
          }}
        />
        <Button type="primary" htmlType="submit">
          {t('common.actions.save')}
        </Button>
      </Form>
    </>
  );
};
