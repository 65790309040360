import { ConnectorType } from 'features/charge-points/types';
import { PowerType } from 'components/view/ConnectorLabel/connectors-labels.const';

export enum ConnectorStatus {
  Off = 'off',
  Faulted = 'faulted',
  On = 'on',
  Busy = 'busy',
  Pre = 'pre',
  Res = 'res',
}

export type Connector = {
  availability: ConnectorStatus;
  type: ConnectorType;
  powerType?: PowerType;
  maxVoltage?: number;
  maxPower?: number;
  maxAmperage?: number;
  format?: 'c' | 's';
  startChargingPluggedEv?: boolean;
  hidden?: boolean;
  qrCode?: string;
};

export type NamedConnector = Connector & { name: number };
