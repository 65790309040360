import { useMemo } from 'react';
import { FirmwareUploadStatus } from 'features/charge-points/types/responses';
import { useTranslation } from 'react-i18next';

import { StatusColor } from './types';

export const useUploadStatusLabels = () => {
  const { t } = useTranslation();

  const uploadStatusLabels = useMemo(() => {
    return {
      [FirmwareUploadStatus.Downloaded]: {
        label: t('chargepoints.firmware.upload-status.downloaded'),
        color: StatusColor.Green,
        isFinished: true,
      },
      [FirmwareUploadStatus.DownloadFailed]: {
        label: t('chargepoints.firmware.upload-status.download-failed'),
        color: StatusColor.Red,
        isFinished: true,
      },
      [FirmwareUploadStatus.Downloading]: {
        label: t('chargepoints.firmware.upload-status.downloading'),
        color: StatusColor.Blue,
        isFinished: false,
      },
      [FirmwareUploadStatus.Idle]: {
        label: t('chargepoints.firmware.upload-status.idle'),
        color: StatusColor.Gray,
        isFinished: false,
      },
      [FirmwareUploadStatus.InstallationFailed]: {
        label: t('chargepoints.firmware.upload-status.installation-failed'),
        color: StatusColor.Red,
        isFinished: true,
      },
      [FirmwareUploadStatus.Installing]: {
        label: t('chargepoints.firmware.upload-status.installing'),
        color: StatusColor.Blue,
        isFinished: false,
      },
      [FirmwareUploadStatus.Installed]: {
        label: t('chargepoints.firmware.upload-status.installed'),
        color: StatusColor.Green,
        isFinished: true,
      },
      [FirmwareUploadStatus.Request]: {
        label: t('chargepoints.firmware.upload-status.request'),
        color: StatusColor.Blue,
        isFinished: false,
      },
    };
  }, [t]);

  const getUploadStatusLabel = (status: FirmwareUploadStatus) => {
    return (
      uploadStatusLabels[status] || {
        label: 'Unknown',
        color: StatusColor.Gray,
        isFinished: false,
      }
    );
  };

  return { uploadStatusLabels, getUploadStatusLabel };
};
