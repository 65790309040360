import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';

import { Coordinates } from '../types';

const MAP_LOAD_ERROR = 'Maps API is not initialized';

export const getSuggestionsOptions = async (
  query: string,
  mapsApi?: YMapsApi,
) => {
  if (!mapsApi) {
    throw new Error(MAP_LOAD_ERROR);
  }

  const suggestions = await mapsApi.suggest(query);
  return suggestions.map((suggestion) => ({
    value: suggestion.value,
    label: suggestion.displayName,
  }));
};

export const getCoordinatesByAddress = async (
  address: string,
  mapsApi?: YMapsApi,
) => {
  if (!mapsApi) {
    throw new Error(MAP_LOAD_ERROR);
  }

  const result = await mapsApi.geocode(address);
  const firstGeoObject = result.geoObjects.get(0);
  const geometry = firstGeoObject?.geometry as ymaps.IPointGeometry;

  const coordinates = geometry?.getCoordinates();

  if (!coordinates) {
    throw new Error('Failed to get coordinates');
  }

  return {
    lat: coordinates[0],
    lng: coordinates[1],
  };
};

export const getAddressByCoordinates = async (
  coordinates: Coordinates,
  mapsApi?: YMapsApi,
) => {
  if (!mapsApi) {
    throw new Error(MAP_LOAD_ERROR);
  }

  const result = await mapsApi.geocode([coordinates.lat, coordinates.lng]);
  const firstGeoObject = result.geoObjects.get(0) as ymaps.GeocodeResult;

  const address = firstGeoObject?.getAddressLine();
  return address;
};
