import { useParams } from 'react-router-dom';
import { Empty } from 'antd';
import dayjs from 'dayjs';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { useChargeTagsPartnerQuery } from 'features/charge-tags/queries';
import { ChargingCardsTable } from 'views/ChargingCardsTable';
import { useTagsColumns } from 'pages/Tags/hooks/useTagsColumns';

export const ChargingCards = () => {
  const { clientId = '' } = useParams<{ clientId?: string }>();
  const partnerId = usePartnerId();

  const { data, isLoading } = useChargeTagsPartnerQuery(
    [partnerId, { clientId, size: 100 }],
    {
      enabled: !!clientId,
    },
  );

  const columns = useTagsColumns({
    partnerId,
  });

  if (!data && !isLoading) {
    return <Empty />;
  }

  return (
    <>
      <ChargingCardsTable
        isLoading={isLoading}
        data={(data ?? []).map((item) => ({
          ...item,
          key: item.id,
          expireAt: item.expireAt
            ? dayjs(item.expireAt).format('DD/MM/YYYY HH:mm')
            : '-',
        }))}
        columns={columns}
      />
    </>
  );
};
