import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
} from 'antd';
import {
  useChargePointsUpdateMutation,
  useChargePointUnbindMutation,
} from 'features/charge-points/queries';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';
import { useIsSubpartner, usePartnerId } from 'core/providers/PartnerProvider';
import { useToastMessage } from 'core/hooks/useToastMessage';

const MAX_SECONDS = 3600; // 1 hour
const MAX_HANGING_NAME = 'maxHangingActiveSec';
const MAX_PENDING_NAME = 'maxPendingSec';
const OUTGOING_ROAMING = 'outgoingRoaming';
const ANONYMOUS_ALLOWED = 'anonymousAllowed';

type FormValues = {
  [MAX_HANGING_NAME]: number | '';
  [MAX_PENDING_NAME]: number | '';
  [OUTGOING_ROAMING]: string[];
  [ANONYMOUS_ALLOWED]?: boolean;
};

export const SettingsTab = () => {
  const [form] = Form.useForm();
  const mutation = useChargePointsUpdateMutation();
  const unbindMutation = useChargePointUnbindMutation();
  const partnerId = usePartnerId();
  const navigate = useNavigate();

  const [isDisable, setDisabled] = useState(true);

  const isSubpartner = useIsSubpartner();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();
  const showToastMessage = useToastMessage();

  const { chargePoint, isOcpp2_0_1 } = useChargePointsDetailsContext();

  const formInitialValues: FormValues = {
    [MAX_HANGING_NAME]: chargePoint?.transCfg?.maxHangingActiveSec / 60 || '',
    [MAX_PENDING_NAME]: chargePoint?.transCfg?.maxPendingSec / 60 || '',
    [OUTGOING_ROAMING]: chargePoint?.roamingEnabled ?? [],
    [ANONYMOUS_ALLOWED]: isOcpp2_0_1
      ? !!chargePoint?.transCfg?.anonymousAllowed
      : false,
  };

  const onFinish = (values: FormValues) => {
    const maxHangingActiveSec = values[MAX_HANGING_NAME]
      ? values[MAX_HANGING_NAME] * 60
      : 0;
    const maxPendingSec = values[MAX_PENDING_NAME]
      ? values[MAX_PENDING_NAME] * 60
      : 0;
    const outgoingRoaming = values[OUTGOING_ROAMING];
    const anonymousAllowed = values[ANONYMOUS_ALLOWED];

    if (maxHangingActiveSec > MAX_SECONDS || maxPendingSec > MAX_SECONDS) {
      showToastMessage(t('chargepoints.errors.max-wait-hours'), 'error');
      return;
    }

    if (maxHangingActiveSec < 0 || maxPendingSec < 0) {
      showToastMessage(t('chargepoints.errors.non-negative-value'), 'error');
      return;
    }

    const data = {
      id: chargePoint.id,
      name: chargePoint.name,
      deviceId: chargePoint.device?.deviceId,
      partnerId: chargePoint.partner?.id,
      transCfg: {
        maxHangingActiveSec,
        maxPendingSec,
        anonymousAllowed,
      },
      roamingEnabled: outgoingRoaming,
    };

    mutation
      .mutateAsync(data)
      .then(() => {
        showToastMessage(t('common.status.success'), 'success');
      })
      .catch((error) => {
        showToastMessage(
          errorT(error?.translationKey, error?.message),
          'error',
        );
      })
      .finally(() => {
        setDisabled(true);
      });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const handleSelectChange = (value: string | string[]) => {
    if (value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const unbindChargePoint = async () => {
    unbindMutation
      .mutateAsync([partnerId, chargePoint.id])
      .then(() => {
        showToastMessage(t('common.status.success'), 'success');
        navigate('/points');
      })
      .catch((error) => {
        showToastMessage(
          errorT(error?.translationKey, error?.message),
          'error',
        );
      });
  };

  return (
    <div>
      <Form
        form={form}
        layout={'vertical'}
        name="charge-point-settings-form"
        onFinish={onFinish}
        initialValues={formInitialValues}
        disabled={isSubpartner}
      >
        <Form.Item
          name={MAX_HANGING_NAME}
          label={`${t('chargepoints.labels.max-hanging-time')}:`}
        >
          <Input
            type="number"
            placeholder={t('chargepoints.placeholders.in-minutes')}
            onChange={handleInputChange}
          />
        </Form.Item>
        <Form.Item
          name={MAX_PENDING_NAME}
          label={`${t('chargepoints.labels.max-pending-time')}:`}
        >
          <Input
            type="number"
            placeholder={`${t('chargepoints.placeholders.in-minutes')}:`}
            onChange={handleInputChange}
          />
        </Form.Item>
        <Form.Item
          name={OUTGOING_ROAMING}
          label={`${t('chargepoints.labels.select-outgoing-roaming')}:`}
        >
          <Select
            placeholder={t('chargepoints.placeholders.select-outgoing-roaming')}
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            options={[{ value: 'ocpi', label: 'OCPI' }]}
            onChange={handleSelectChange}
          ></Select>
        </Form.Item>
        {isOcpp2_0_1 && (
          <Form.Item name={ANONYMOUS_ALLOWED} valuePropName="checked">
            <Checkbox onChange={() => setDisabled(false)}>
              {`${t('chargepoints.labels.anonymous-allowed')}: `}
            </Checkbox>
          </Form.Item>
        )}
        <Row justify="space-between">
          <Col>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={mutation.isLoading}
                className="login-form-button"
                disabled={isDisable}
              >
                {t('common.actions.save-changes')}
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Popconfirm
              title={t('chargepoints.text.unbind-chargepoint')}
              placement="topLeft"
              description={
                <p>
                  {t('chargepoints.text.unbind-chargepoint.warning1')}
                  <br />
                  {t('chargepoints.text.unbind-chargepoint.warning2')}
                </p>
              }
              okText={t('chargepoints.text.yes')}
              cancelText={t('common.actions.cancel')}
              onConfirm={unbindChargePoint}
            >
              <Button
                type="default"
                htmlType="button"
                loading={unbindMutation.isLoading}
                disabled={isSubpartner}
              >
                {t('chargepoints.actions.unbind-chargepoint')}
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
