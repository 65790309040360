import { Coordinates } from '../types';

const AUTOCOMPLETE_SERVICE_ERROR =
  'Google Maps AutocompleteService is not initialized';
const PLACES_SERVICE_ERROR = 'Google Maps PlacesService is not initialized';

export const getSuggestionsOptions = async (
  query: string,
  autocompleteService?: google.maps.places.AutocompleteService,
): Promise<{ value: string; placeId?: string }[]> => {
  if (!autocompleteService) {
    throw new Error(AUTOCOMPLETE_SERVICE_ERROR);
  }

  return new Promise((resolve, reject) => {
    autocompleteService.getQueryPredictions({ input: query }, (response) => {
      if (!response) {
        reject(new Error('Failed to get suggestions'));
        return;
      }

      const suggestions = response.map((item) => ({
        value: item.description,
        placeId: item.place_id,
      }));

      resolve(suggestions);
    });
  });
};

export const getCoordinatesByAddress = async (
  addressId?: string,
  placesService?: google.maps.places.PlacesService,
): Promise<Coordinates> => {
  if (!placesService || !addressId) {
    throw new Error(PLACES_SERVICE_ERROR);
  }

  return new Promise((resolve, reject) => {
    placesService.getDetails({ placeId: addressId }, (response) => {
      if (!response) {
        reject(new Error('Failed to get coordinates'));
        return;
      }

      const coordinates = response?.geometry?.location;
      if (!coordinates) {
        reject(new Error('Failed to get coordinates'));
        return;
      }

      resolve({
        lat: coordinates.lat(),
        lng: coordinates.lng(),
      });
    });
  });
};

export const getAddressByCoordinates = async (
  coordinates: Coordinates,
  language = 'en',
) => {
  if (window.google) {
    const geocoder = new window.google.maps.Geocoder();
    const result = await geocoder.geocode({
      language,
      location: coordinates,
    });

    const address = result.results[0]?.formatted_address;

    return address;
  }
};
