import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Empty, Spin, Tabs, Typography } from 'antd';
import { Padding } from 'components/Padding';
import { useClientQuery } from 'features/clients/queries';
import { usePartnersBillingClientsBalance } from 'features/partners/queries';
import { mePartnerId, usePartnerId } from 'core/providers/PartnerProvider';

import styles from './ClientDetail.module.css';
import { TransactionsTab } from './TransactionsTab';
import { Description } from './Description';
import { PaymentHistory } from './PaymentHistory';
import { ChargingCards } from './ChargingCards/ChargingCards';

export const ClientDetail = () => {
  const partnerId = usePartnerId();
  const { clientId = '' } = useParams<{ clientId?: string }>();

  const { t } = useTranslation();

  const tabs = [
    {
      label: t('clients.labels.transactions'),
      key: 'transactions',
      children: <TransactionsTab />,
    },
    {
      label: t('clients.labels.top-up-history'),
      key: 'PaymentHistory',
      children: <PaymentHistory />,
    },
    {
      label: 'Charging Cards',
      key: 'ChargingCards',
      children: <ChargingCards />,
    },
  ];

  const {
    isError,
    isLoading,
    data: client,
  } = useClientQuery(clientId, {
    enabled: !!clientId,
  });

  const {
    isError: _isError,
    isLoading: _isLoading,
    data: balance,
  } = usePartnersBillingClientsBalance(partnerId, clientId, {
    enabled: partnerId !== mePartnerId,
  });

  const [{ amount, currency }] = balance || [{ amount: 0, currency: 'USD' }];

  if (isLoading || _isLoading) {
    return <Spin />;
  }

  if (isError || _isError) {
    return <div className={styles.container}>{t('common.status.failed')}</div>;
  }

  if (!client) {
    return <Empty className={styles.container} />;
  }

  const fullName = `${client?.lastName || ''} ${client?.firstName || ''} ${
    client?.middleName || ''
  }`;

  return (
    <>
      <div className={styles.header}>
        <div>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {fullName ?? ''}
          </Typography.Title>
        </div>
      </div>
      <div className={styles.content}>
        <Description client={client} amount={amount} currency={currency} />
      </div>
      <Padding noStart>
        <Tabs
          type="line"
          defaultActiveKey="transactions"
          size="large"
          items={tabs}
        />
      </Padding>
    </>
  );
};
