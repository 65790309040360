import { useEffect, useRef } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  GoogleMap as GoogleMapComponent,
  MarkerF as Marker,
  useJsApiLoader,
} from '@react-google-maps/api';

import type { GoogleMapProps } from '../types';

import { googleMapsApiOptions } from './google-map-options';
import styles from './Map.module.css';

export const GoogleMap = ({
  markerCoordinates,
  initialCoordinates,
  onReady,
  onChangeCoordinates,
}: GoogleMapProps) => {
  const { i18n } = useTranslation();
  const centerRef = useRef(initialCoordinates);
  const mapRef = useRef<google.maps.Map | null>(null);

  // reload the page when language changes to re-init google maps
  i18n.on('languageChanged', () => {
    window.location.reload();
  });

  const { isLoaded } = useJsApiLoader({
    ...googleMapsApiOptions,
    language: i18n.language,
  });

  const handleClick = (event: google.maps.MapMouseEvent) => {
    if (!event.latLng) {
      return;
    }

    const { lat, lng } = event.latLng;
    onChangeCoordinates({ lat: lat(), lng: lng() });
  };

  const handleInit = (map: google.maps.Map) => {
    mapRef.current = map;
    onReady?.(map);
  };

  useEffect(() => {
    try {
      mapRef.current?.panTo(markerCoordinates);
    } catch (e) {
      console.warn(e);
    }
  }, [markerCoordinates]);

  if (!isLoaded) {
    return <Spin />;
  }

  return (
    <GoogleMapComponent
      zoom={12}
      center={centerRef.current}
      mapContainerClassName={styles.map}
      onLoad={handleInit}
      onClick={handleClick}
    >
      <Marker position={markerCoordinates} />
    </GoogleMapComponent>
  );
};
