import { Responses } from 'features/charge-points/types';
import { AuthHelpers } from 'features/auth';
import * as Services from 'core/services';
import * as Types from 'core/types';

export async function fetchChargePointFirmwareUpdate<
  TResponse = Responses.ResChargePointsFirmware,
  TError = Types.HttpError,
>(partnerId: string, chargePointId: string, formData: FormData) {
  try {
    const header = await AuthHelpers.authHeader();
    const response = await Services.apiService.post<TResponse>(
      `partners/${partnerId}/charging/charge-points/${chargePointId}/firmware`,
      formData,
      {
        headers: { ...header },
      },
    );
    return response.data;
  } catch (error: any) {
    if (!error.response) {
      throw error;
    }
    return Promise.reject(error.response.data as TError);
  }
}
