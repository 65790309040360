import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import * as Types from 'core/types';

import { Responses, Requests } from '../types';
import { fetchPartnerAnalytic } from '../fetches';

export const USE_PARTNER_ANALYTIC_QUERY_KEY = 'partner.analytic';

export function usePartnerAnalyticQuery(
  data: Requests.ReqPartnerAnalytic,
  options?: UseQueryOptions<
    Responses.ResPartnerAnalytic,
    Types.HttpError,
    Responses.ResPartnerAnalytic,
    string[]
  >,
) {
  return useQuery(
    [USE_PARTNER_ANALYTIC_QUERY_KEY, data.partnerId, data.from, data.to],
    () => fetchPartnerAnalytic(data),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      ...options,
    },
  );
}
