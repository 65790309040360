import { FC, memo } from 'react';
import cn from 'classnames';
import { connectorIcons } from 'components/view/ConnectorLabel/connector-icons.const';
import {
  connectorsLabels,
  connectorStatusColor,
} from 'components/view/ConnectorLabel/connectors-labels.const';
import { ConnectorType } from 'features/charge-points/types';
import { ConnectorStatus } from 'core/types/Connector';

import s from './ConnectorLabel.module.css';

type Props = {
  className?: string;
  type: ConnectorType;
  status?: ConnectorStatus;
};

const ConnectorLabel: FC<Props> = ({ className, type, status }) => {
  return (
    <div
      className={cn(s.wrapper, className)}
      style={{ color: status ? connectorStatusColor[status] : 'inherit' }}
    >
      <span className={s.icon}>{connectorIcons[type]}</span>
      <span>{connectorsLabels[type]}</span>
    </div>
  );
};

export default memo(ConnectorLabel);
