import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { YMapsApi } from '@pbe/react-yandex-maps/typings/util/typing';

import type { Coordinates, MapProps, MapRefType, MapType } from './types';
import { YandexMap } from './YandexMap/YandexMap';
import { GoogleMap } from './GoogleMap/GoogleMap';
import {
  getSuggestionsOptions as getYandexSuggestionOptions,
  getCoordinatesByAddress as getYandexCoordinatesByAddress,
  getAddressByCoordinates as getYandexAddressByCoordinates,
} from './YandexMap/utils';
import {
  getSuggestionsOptions as getGoogleSuggestionOptions,
  getCoordinatesByAddress as getGoogleCoordinatesByAddress,
  getAddressByCoordinates as getGoogleAddressByCoordinates,
} from './GoogleMap/utils';

const getMapType = (type?: MapType) => {
  const hasYandexMapKey = !!process.env.REACT_APP_YANDEX_MAP_KEY;
  const hasGoogleMapKey = !!process.env.REACT_APP_GOOGLE_MAPS_KEY;

  switch (true) {
    case !!type:
      return type;
    case hasGoogleMapKey:
      return 'google';
    case hasYandexMapKey:
      return 'yandex';
    default:
      return 'yandex';
  }
};

export const Map = forwardRef<MapRefType, MapProps>((props, ref) => {
  const { type: defaultType, ...mapProps } = props;

  const { i18n } = useTranslation();

  const mapType = getMapType(defaultType);

  const autoCompleteServiceRef =
    useRef<google.maps.places.AutocompleteService>();
  const placesServiceRef = useRef<google.maps.places.PlacesService>();
  const mapApiRef = useRef<YMapsApi>();

  const onGoogleMapReady = useCallback((map: google.maps.Map) => {
    if (window.google.maps.places.AutocompleteService) {
      const { AutocompleteService, PlacesService } = window.google.maps.places;

      autoCompleteServiceRef.current = new AutocompleteService();
      placesServiceRef.current = new PlacesService(map);
    }
  }, []);

  const onYandexMapReady = useCallback((map: YMapsApi) => {
    mapApiRef.current = map;
  }, []);

  useImperativeHandle(ref, () => ({
    getSuggestionsOptions: async (query: string) => {
      if (mapType === 'yandex') {
        return getYandexSuggestionOptions(query, mapApiRef.current);
      }

      if (mapType === 'google') {
        return getGoogleSuggestionOptions(
          query,
          autoCompleteServiceRef.current,
        );
      }
    },
    getCoordinatesByAddress: async (address: string, addressId?: string) => {
      if (mapType === 'yandex') {
        return getYandexCoordinatesByAddress(address, mapApiRef.current);
      }

      if (mapType === 'google') {
        return getGoogleCoordinatesByAddress(
          addressId,
          placesServiceRef.current,
        );
      }
    },
    getAddressByCoordinates: async (coordinates: Coordinates) => {
      if (mapType === 'yandex') {
        return getYandexAddressByCoordinates(coordinates, mapApiRef.current);
      }

      if (mapType === 'google') {
        return getGoogleAddressByCoordinates(coordinates, i18n.language);
      }
    },
  }));

  if (mapType === 'google') {
    return <GoogleMap {...mapProps} onReady={onGoogleMapReady} />;
  } else if (mapType === 'yandex') {
    return <YandexMap {...mapProps} onReady={onYandexMapReady} />;
  }
});
