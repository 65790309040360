import { CollapseProps } from 'antd';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LocationDetailsForm } from '../LocationDetailsForm';
import { LocationImages } from '../LocationImages';
import { LocationPlaces } from '../LocationPlaces';
import { LocationChargePoints } from '../LocationChargePoints';
import { LocationWorkingHours } from '../LocationWorkingHours';

import type { UseAccordionItemsProps } from './types';

export const useAccordionItems = ({
  location,
  chargePoints,
}: UseAccordionItemsProps): CollapseProps['items'] => {
  const { t } = useTranslation();
  const routeLocation = useLocation();
  const isInLocationsRoute = routeLocation.pathname.includes('/locations');

  const shouldShowChargePoints = isInLocationsRoute
    ? chargePoints.length > 0
    : chargePoints.length > 1;

  const accordionItems: CollapseProps['items'] = [
    {
      key: '1',
      label: t('locations.titles.location-details'),
      children: <LocationDetailsForm location={location} />,
    },
    {
      key: '2',
      label: t('locations.titles.location-working-hours'),
      children: (
        <LocationWorkingHours
          locationId={location.id}
          workingHours={location.workingHours}
        />
      ),
    },
    {
      key: '3',
      label: t('locations.titles.location-images'),
      children: (
        <LocationImages
          locationImages={location?.images}
          locationId={location.id}
        />
      ),
    },
    {
      key: '4',
      label: t('locations.titles.location-places'),
      children: (
        <LocationPlaces
          locationPlaces={location?.places ?? []}
          locationId={location.id}
        />
      ),
    },
    shouldShowChargePoints && {
      key: '5',
      label: t('locations.titles.location-chargepoints'),
      children: <LocationChargePoints chargePoints={chargePoints} />,
    },
  ].filter(Boolean) as CollapseProps['items'];

  return accordionItems;
};
