import { App } from 'antd';
import { MessageType } from 'antd/es/message/interface';
import { useCallback } from 'react';

export const useToastMessage = () => {
  const { message } = App.useApp();

  type MessageTypeKey = 'success' | 'error' | 'info' | 'warning' | 'loading';

  const showToastMessage = useCallback(
    (
      content: string,
      type: MessageTypeKey = 'info',
      duration: number = 3,
    ): MessageType => {
      const messageFunction = message[type];
      if (messageFunction) {
        return messageFunction(content, duration);
      }
      throw new Error(`Unsupported message type: ${type}`);
    },
    [message],
  );

  return showToastMessage;
};
