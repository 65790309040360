import { Button, Typography } from 'antd';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'components/Pagination';
import { useTranslation } from 'react-i18next';
import { useChargeTagsPartnerQuery } from 'features/charge-tags/queries';
import { Padding } from 'components/Padding';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { usePartnerHashtagsQuery } from 'features/hashtags/queries';
import { ChargingCardsTable } from 'views/ChargingCardsTable';

import styles from './Tags.module.css';
import { TagsAssignClient } from './TagsAssignClient';
import { useTagsColumns } from './hooks/useTagsColumns';
import { TagsFilter } from './TagsFilter';
import { useTagsFilter } from './TagsFilter/useTagsFilter';

const PAGE_SIZE = 10;

export function Tags() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [assigning, setAssigning] = useState<string | null>(null);

  const partnerId = usePartnerId();

  const [filterState, dispatch] = useTagsFilter();

  const { t } = useTranslation();

  const openAssigningModal = (tagId: string) => {
    setAssigning(tagId);
  };

  const closeAssigningModal = () => {
    setAssigning(null);
  };

  const searchParams = useMemo(() => {
    return {
      index: page,
      size: PAGE_SIZE,
      tag: filterState.tag,
      hashtags: filterState.hashTags.join(','),
    };
  }, [page, filterState]);

  const { data, isLoading } = useChargeTagsPartnerQuery([
    partnerId,
    searchParams,
  ]);

  const { data: hashtags } = usePartnerHashtagsQuery([partnerId]);

  const columns = useTagsColumns({
    partnerId,
    openAssigningModal,
  });

  return (
    <>
      <TagsAssignClient tagId={assigning} onClose={closeAssigningModal} />
      <Padding isHorizontal bottomBorder>
        <div>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {t('rfid_tags.titles.rfid-cards')}
          </Typography.Title>
        </div>
        <div>
          <Button type={'primary'} onClick={() => navigate('/tags/create')}>
            {t('common.actions.create')}
          </Button>
        </div>
      </Padding>
      <Padding noEnd>
        <TagsFilter
          filterState={filterState}
          dispatch={dispatch}
          hashtags={hashtags}
        />
      </Padding>
      <Padding>
        <ChargingCardsTable
          isLoading={isLoading}
          data={(data ?? []).map((item) => ({
            ...item,
            key: item.id,
            expireAt: item.expireAt
              ? dayjs(item.expireAt).format('DD/MM/YYYY HH:mm')
              : '-',
          }))}
          columns={columns}
        />
      </Padding>
      <Padding>
        <Pagination
          page={page}
          hasNext={data?.length === PAGE_SIZE}
          hasPrev={page > 1}
          onChange={(_, index) => setPage(index)}
        >
          <Typography>{`${t('common.pagination.page')}: ${page}`}</Typography>
        </Pagination>
      </Padding>
    </>
  );
}
