export interface ResChargePointsFirmware {
  acceptedAt: string;
  cpDeviceId: string;
  cpId: string;
  fileId: string;
  fileUrl: string;
  id: string;
  requestedAt: string;
  status: FirmwareUploadStatus;
}

export enum FirmwareUploadStatus {
  Downloaded = 'downloaded',
  DownloadFailed = 'downloadFailed',
  Downloading = 'downloading',
  Idle = 'idle',
  InstallationFailed = 'installationFailed',
  Installing = 'installing',
  Installed = 'installed',
  Request = 'request',
}
