import dayjs, { Dayjs } from 'dayjs';
import { getWeekDayLabel, weekDays } from 'core/types/WeekDay';
import {
  LocationRegularHours,
  LocationWorkingDaysEnum,
} from 'features/locations/types';

import { FormValues, LocationWorkingHoursFormProps } from './types';

export const defaultLocationWorkingHoursData = weekDays.map((day) => ({
  label: getWeekDayLabel(day),
  value: day as LocationWorkingDaysEnum,
  from: null,
  to: null,
}));

export const defaultLocationWorkingHours =
  defaultLocationWorkingHoursData.reduce((acc, item) => {
    acc[item.value] = [item.from, item.to];
    return acc;
  }, {} as Record<LocationWorkingDaysEnum, [Dayjs | null, Dayjs | null]>);

export const prepareFormValues = (
  locationRegularWorkingHours: LocationWorkingHoursFormProps['workingHours']['regularHours'],
) => {
  const formValues = { ...defaultLocationWorkingHours };

  Object.entries(locationRegularWorkingHours ?? {}).forEach(([key, value]) => {
    if (value) {
      formValues[key as LocationWorkingDaysEnum] = [
        dayjs(value.from, 'HH:mm'),
        dayjs(value.to, 'HH:mm'),
      ];
    }
  });

  return formValues;
};

export const getPayloadFromFormValues = (formValues: FormValues) => {
  const filteredValues = Object.entries(formValues).filter(
    ([, value]) => value && value[0] !== null && value[1] !== null,
  ) as [LocationWorkingDaysEnum, [Dayjs, Dayjs]][];

  const payload = filteredValues.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: {
        from: value[0].format('HH:mm'),
        to: value[1].format('HH:mm'),
      },
    };
  }, {} as LocationRegularHours);

  return payload;
};
