import { Connector } from 'core/types/Connector';

export type ChargePointLocation = {
  address: string;
  lat: number;
  lon: number;
  countryCode?: string;
  city: string;
  postalCode: string;
  state: string;
  timeZone: string;
  facilities: number[];
};

export enum BusinessDetailsKeys {
  buyCost = 'buyCost',
  installationPrice = 'installationPrice',
  kwhCost = 'kwhCost',
  rentCost = 'rentCost',
  revenueShare = 'revenueShare',
}

export type ChargePointPartnerItemBusinessDetails = {
  [key in BusinessDetailsKeys]?: number;
};

export interface ChargePointPartnerItem {
  businessDetails?: ChargePointPartnerItemBusinessDetails;
  connectors: Record<number, Connector>;
  device: {
    chargePointModel: string;
    chargePointSerialNumber: string;
    chargePointVendor: string;
    deviceId: string;
    firmwareVersion: string;
    iccId: string;
    imsi: string;
    meterSerialNumber: string;
    meterType: string;
  };
  id: string;
  name: string;
  description: string;
  /** @deprecated */
  location: ChargePointLocation;
  locationId: string;
  partner: {
    id: string;
    assignedAt: string;
    name?: string;
  };
  ownerPartnerId: string;
  ownerPartnerName: string;
  status: string;
  tariffGroupId: string;
  tariffGroupName: string;
  transCfg: {
    maxHangingActiveSec: number;
    maxPendingSec: number;
    anonymousAllowed?: boolean;
  };
  roamingEnabled?: string[];
  externalSource?: string;
  currency: string;
  termsUrl: string;
  hashTags: string[];
  images: {
    category: string;
    fileId: string;
    height: number;
    thumbnailFileId: string;
    thumbnailUrl: string;
    type: string;
    url: string;
    width: number;
  }[];
  emergencyButtonTime?: string;
  lastConnectionTime?: string;
  ocppVersion?: string;
  qrCode?: string;
}
