import { FilterTwoTone } from '@ant-design/icons';
import { Form, Popover, Select, Table, Typography, Button, Empty } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Pagination } from 'components/Pagination';
import { useChargePointsDevicesLogsQuery } from 'features/charge-points/queries';
import { useIsSubpartner, usePartnerId } from 'core/providers/PartnerProvider';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';
import { ChargePointTransactionForm } from 'pages/ChargePointsDetails/Forms/ChargePointTransactionForm';
import { ChargePointUnlockConnectorForm } from 'pages/ChargePointsDetails/Forms/ChargePointUnlockConnectorForm';

import styles from './ChargePointTransaction.module.css';
import { ResetConfirm } from './ResetConfirm';
import { useChargePointTransactionTableColumns } from './useChargePointTrransactionTableColumns';

const EVENTS = [
  'start-trans.conf',
  'remote-trans.rq',
  'start-trans',
  'remote-start-trans-conf',
  'hb',
  'conn',
  'mv',
  'trigger-msg-conf',
  'auth',
  'trigger-msg.rq',
  'remote-stop-trans-conf',
  'boot',
  'stop-trans',
  'disconn',
  'status',
  'remote-trans-stop.rq',
  'auth.conf',
  'stop-trans.conf',
];

export function ChargePointTransaction() {
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    severities: [],
    inclEvents: [],
  });
  const { chargePoint } = useChargePointsDetailsContext();
  const [resetting, setResetting] = useState<'Hard' | 'Soft' | null>(null);
  const [startTransactionOpened, setStartTransactionOpened] = useState(false);
  const [unlockConnectorOpened, setUnlockConnectorOpened] = useState(false);

  const { t } = useTranslation();

  const isSubpartner = useIsSubpartner();

  const columns = useChargePointTransactionTableColumns();

  const { id: chargePointId = '' } = useParams<{ id: string }>();
  const partnerId = usePartnerId();

  const logs = useChargePointsDevicesLogsQuery(
    partnerId,
    chargePointId,
    {
      size: 10,
      index: page,
      exclEvents: 'hb',
      deviceId: chargePoint?.device.deviceId,
      severities: params?.severities?.join(','),
      inclEvents: params?.inclEvents?.join(','),
    },
    {
      keepPreviousData: true,
      enabled: !!chargePoint?.device.deviceId && !isSubpartner,
    },
  );

  if (!chargePoint || isSubpartner) {
    return <Empty />;
  }

  const filterContent = () => (
    <div style={{ width: 200 }}>
      <Form
        layout={'vertical'}
        onValuesChange={(values) => {
          setParams(values);
          setPage(1);
        }}
      >
        <Form.Item
          name={'severities'}
          label={`${t('chargepoints.labels.severities')}:`}
        >
          <Select mode={'tags'} showArrow>
            <Select.Option value={'D'}>Debug</Select.Option>
            <Select.Option value={'E'}>Error</Select.Option>
            <Select.Option value={'W'}>Warning</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name={'inclEvents'}
          label={`${t('chargepoints.labels.include-events')}:`}
        >
          <Select mode={'tags'} showArrow>
            {EVENTS.map((event) => (
              <Select.Option key={event} value={event}>
                {event}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <div style={{ paddingBlockEnd: 16 }}>
      <ResetConfirm
        resetType={resetting}
        onClose={() => setResetting(null)}
      />
      <ChargePointTransactionForm
        isOpen={startTransactionOpened}
        chargePoint={chargePoint}
        onCancel={() => setStartTransactionOpened(false)}
      />
      <ChargePointUnlockConnectorForm
        isOpen={unlockConnectorOpened}
        chargePoint={chargePoint}
        onCancel={() => setUnlockConnectorOpened(false)}
      />
      <div className={styles.charge_point_transaction__filter}>
        <div className={styles['charge_point_transaction__filter-reset']}>
          <Button type="primary" onClick={() => setResetting('Hard')}>
            {t('chargepoints.actions.hard-reset')}
          </Button>
          <Button type="primary" onClick={() => setResetting('Soft')}>
            {t('chargepoints.actions.soft-reset')}
          </Button>
          <Button
            type="primary"
            onClick={() => setStartTransactionOpened(true)}
          >
            {t('chargepoints.actions.manual-start-transaction')}
          </Button>
          <Button type="primary" onClick={() => setUnlockConnectorOpened(true)}>
            {t('chargepoints.actions.unlock-connector')}
          </Button>
        </div>
        <Popover placement={'leftTop'} content={filterContent} trigger="click">
          <FilterTwoTone style={{ fontSize: '20px' }} />
        </Popover>
      </div>
      <div
        style={{
          border: '1px solid #eee',
          marginTop: 16,
          borderRadius: 8,
          overflow: 'hidden',
        }}
      >
        <Table
          size={'small'}
          pagination={false}
          scroll={{ x: 'max-content' }}
          className={styles.charge_point_transaction__table}
          dataSource={logs.data?.items?.map((item, idx) => ({
            key: idx,
            body: item.body,
            connectorId: item.connectorId ?? '-',
            cpDeviceId: item.cpDeviceId || '-',
            errCode: item.errCode || '-',
            errMsg: item.errMsg || '-',
            event: item.event || '-',
            eventCode: item.eventCode || '-',
            requestId: item.requestId || '-',
            severity: item.severity || '-',
            tagId: item.tagId || '-',
            transactionId: item.transactionId || '-',
            vendorErrCode: item.vendorErrCode ?? '-',
            createdAt: dayjs(item.createdAt).format('DD.MM.YYYY HH:mm:sss'),
          }))}
          columns={columns}
        />
      </div>
      <div style={{ marginTop: 16 }}>
        <Pagination
          page={page}
          hasNext={logs.data?.items?.length === 10}
          hasPrev={page > 1}
          onChange={(_, index) => setPage(index)}
        >
          <Typography>{`${t('common.pagination.page')}: ${page}`}</Typography>
        </Pagination>
      </div>
    </div>
  );
}
