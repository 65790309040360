import { Table } from 'antd';

import { ChargingCardsTableProps } from './types';

export const ChargingCardsTable = ({
  isLoading,
  data,
  columns,
}: ChargingCardsTableProps) => {
  return (
    <Table
      size={'small'}
      pagination={false}
      loading={isLoading}
      dataSource={data}
      columns={columns}
      scroll={{ x: 1300 }}
    />
  );
};
