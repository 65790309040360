import * as Types from 'core/types';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { ChargePointsTypes } from '..';
import { fetchChargePointFirmwareUpdate } from '../fetches';

export function useChargePointsFirmwareMutation(
  options?: UseMutationOptions<
    ChargePointsTypes.Responses.ResChargePointsFirmware,
    Types.HttpError,
    Parameters<typeof fetchChargePointFirmwareUpdate>
  >,
) {
  return useMutation((args) => fetchChargePointFirmwareUpdate(...args), {
    retry: 0,
    ...options,
  });
}
