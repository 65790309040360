import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { StatisticsDateOption } from '../types';

export const useStatisticsDateOptions = (): StatisticsDateOption[] => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        label: t('dashboard.labels.today'),
        value: 'today',
        dates: [dayjs().startOf('day'), dayjs().endOf('day')],
      },
      {
        label: t('dashboard.labels.yesterday'),
        value: 'yesterday',
        dates: [
          dayjs().subtract(1, 'day').startOf('day'),
          dayjs().subtract(1, 'day').endOf('day'),
        ],
      },
      {
        label: t('dashboard.labels.current-week'),
        value: 'currentWeek',
        dates: [dayjs().startOf('week'), dayjs().endOf('week')],
      },
      {
        label: t('dashboard.labels.current-month'),
        value: 'currentMonth',
        dates: [dayjs().startOf('month'), dayjs().endOf('month')],
      },
      {
        label: t('dashboard.labels.current-quarter'),
        value: 'currentQuarter',
        dates: [
          dayjs()
            .startOf('month')
            .month(Math.floor(dayjs().month() / 3) * 3),
          dayjs()
            .endOf('month')
            .month(Math.floor(dayjs().month() / 3) * 3 + 2)
            .endOf('month'),
        ],
      },
      {
        label: t('dashboard.labels.last-30-days'),
        value: 'last30Days',
        dates: [dayjs().subtract(30, 'day'), dayjs()],
      },
      {
        label: t('dashboard.labels.last-90-days'),
        value: 'last90Days',
        dates: [dayjs().subtract(90, 'day'), dayjs()],
      },
      {
        label: t('dashboard.labels.from-start-of-year'),
        value: 'fromStartOfYear',
        dates: [dayjs().startOf('year'), dayjs()],
      },
    ];
  }, [t]);
};
