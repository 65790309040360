import { useTranslation } from 'react-i18next';
import { useErrorTranslation } from 'i18n/useErrorTranslation';
import { Button, Col, Row, Typography, Upload } from 'antd';
import { ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { useChargePointsDetailsContext } from 'pages/ChargePointsDetails/ChargePointsDetailsContext';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import {
  USE_CHARGE_POINT_FIRMWARE_QUERY_KEY,
  useChargePointFirmwareQuery,
  useChargePointsFirmwareMutation,
} from 'features/charge-points/queries';
import { HttpError } from 'core/types';
import { showToastMessage } from 'core/utils/showToastMessage';

import { useUploadStatusLabels } from './useUploadStatusLabels';

export const FirmwareTab = () => {
  const partnerId = usePartnerId();
  const { chargePoint } = useChargePointsDetailsContext();
  const updateFirmware = useChargePointsFirmwareMutation();
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const { t: errorT } = useErrorTranslation();

  const { getUploadStatusLabel } = useUploadStatusLabels();

  const {
    data: currentFirmware,
    isLoading: isFirmwareLoading,
    isRefetching,
    refetch,
  } = useChargePointFirmwareQuery(partnerId, chargePoint.id);

  const currentUploadStatus = currentFirmware?.status
    ? getUploadStatusLabel(currentFirmware.status)
    : null;

  const isLoading = isFirmwareLoading || updateFirmware.isLoading;
  const isUploadDisabled =
    isLoading || (!!currentUploadStatus && !currentUploadStatus.isFinished);

  const handleUpload = async ({ file }: UploadRequestOption) => {
    if (!(file instanceof File)) {
      showToastMessage('error', t('chargepoints.errors.upload-error'));
      return;
    }

    const maxSize = 10 * 1024 * 1024; // 10MB

    if (file.size > maxSize) {
      showToastMessage(
        'error',
        t('chargepoints.errors.upload-file-size-error'),
      );
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file);

      await updateFirmware
        .mutateAsync([partnerId, chargePoint.id, formData])
        .then((data) =>
          queryClient.setQueryData(
            [USE_CHARGE_POINT_FIRMWARE_QUERY_KEY, partnerId, chargePoint.id],
            data,
          ),
        );
    } catch (error) {
      if ((error as HttpError).translationKey) {
        showToastMessage('error', errorT((error as HttpError).translationKey));
      } else {
        showToastMessage('error', t('chargepoints.errors.upload-error'));
      }
    }
  };

  return (
    <>
      <Row gutter={16} align={'middle'}>
        <Col>
          <Upload
            maxCount={1}
            customRequest={handleUpload}
            showUploadList={false}
            accept="*"
          >
            <Button
              type="primary"
              icon={<UploadOutlined />}
              loading={isLoading}
              disabled={isUploadDisabled}
            >
              {t('chargepoints.text.upload')}
            </Button>
          </Upload>
        </Col>
        {currentUploadStatus && (
          <Col
            style={{
              color: currentUploadStatus.color,
            }}
          >
            {currentUploadStatus.label}
          </Col>
        )}
        <Col>
          {currentUploadStatus && !currentUploadStatus.isFinished && (
            <Button
              type="primary"
              icon={<ReloadOutlined />}
              loading={isRefetching}
              onClick={() => refetch()}
            >
              Refresh
            </Button>
          )}
        </Col>
      </Row>
      {currentFirmware?.fileId && (
        <Row style={{ marginTop: 16 }}>
          <Typography.Paragraph
            copyable={{ text: currentFirmware.fileId }}
            style={{
              color: 'gray',
            }}
          >
            File ID: {currentFirmware.fileId.substring(0, 12)}...
          </Typography.Paragraph>
        </Row>
      )}
    </>
  );
};
